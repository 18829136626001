<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    color="#263238"
    mobile-breakpoint="960"
    app
    width="280"
    v-bind="$attrs"
  >
    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-img
          :src="logo"
          class="drawer-img"
        />
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <br>
    <div class="bottom-div">
      <br>
      <br>
      <div>
        <!-- <v-btn
          class="my-logout-button-style"
          text
          @click="logOut()"
        >
          <v-icon
            left
          >
            mdi-logout
          </v-icon>
          Logout
        </v-btn> -->
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import drawerItems from 'src/data/drawer-items';
import { cdnUrl } from 'src/app-globals';
import ModuleHelper from 'src/helpers/module-helper';
import global from 'src/mixins/global';

// Utilities
import {
  mapState,
} from 'vuex';

export default {
  name: 'DashboardCoreDrawer',
  mixins: [global],
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    logo: `${cdnUrl}/website/SwiftCheckInEzyBuildLogoNew.png`,
    showModal: false,
    helper: new ModuleHelper(),
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get () {
        return this.$store.state.drawer;
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val);
      },
    },
    computedItems () {
      return this.items.map(this.mapItem);
    },
    profile () {
      return {
        avatar: true,
        title: 'DBS',
      };
    },
    organizationModules () {
      return this.$store.getters['orgsettings/getOrgSettings'];
    },
  },
  watch: {
    organizationModules (val) {
      this.setDrawerItems();
    },
  },

  async mounted () {
    this.loading = true;
    await this.$store.dispatch('orgsettings/fetchOrgSettings');
    this.loading = false;
    this.setDrawerItems();
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    setDrawerItems () {
      var items = drawerItems;
    var finalItems = [];
    for (var i = 0; i < items.length; i++) {
  if (this.helper.isModuleExist(items[i].module) === true) {
    finalItems.push(items[i]);
  }
}
this.items = finalItems;
      },
logOut () {
  this.$router.push({ name: 'Logout' });
},
    },
  };
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
<style scoped>
.bottom-div {
  position: relative;
  margin-top: 50%;
  bottom: 10px;
}

.my-logout-button-style {
  font-size: 13px;
  margin: 0 auto;
  text-align: center;
  display: block;
}

fieldset {
  border: 1px solid;
  border-color: #E0E0E0;
  width: 200px;
  height: 130px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-radius: 15px;
  margin: 0 auto;

}

legend {
  vertical-align: middle;
}

#my-publish-button-style {
  background-color: #FF3700;
  color: #EEEEEE;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

.my-container-text-style {
  color: #EEEEEE;
  font-size: 16px;
  margin-top: 5px;
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.drawer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 150px;
}
</style>
