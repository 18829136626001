import constants from '../constants';
const drawerItems = [
        {
          module: constants.MODULE_DASHBOARD,
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/pages/dashboard',
        },
        // {
        //   module: constants.MODULE_USER_PROFILE,
        //   icon: 'mdi-account-circle',
        //   title: 'My Profile',
        //   to: '/user',
        // },
        {
          module: constants.MODULE_MEMBERS,
          icon: 'mdi-account-multiple',
          title: 'Members',
          to: '/members',
        },
        {
          module: constants.MODULE_COMPANIES,
          icon: 'mdi-domain',
          title: 'Companies',
          to: '/companies',
        },
        {
          module: constants.MODULE_SETTINGS,
          title: 'Safety',
          icon: 'mdi-shield-check',
          to: '/feature-settings',
        },
        {
          module: constants.MODULE_APP_USERS,
          icon: 'mdi-account-group',
          title: 'App Users',
          to: '/app-users',
        },
        {
          module: constants.MODULE_PROJECTS,
          icon: 'mdi-sitemap-outline',
          title: 'Projects',
          to: '/projects',
        },
        {
          module: constants.MODULE_PROJECT_GATES,
          icon: 'mdi-office-building-marker',
          title: 'Project Gates',
          to: '/project-gates',
        },
        {
          module: constants.MODULE_ATTENDANCE,
          icon: 'mdi-calendar-check-outline',
          title: 'Attendance',
          to: '/attendance',
        },
        {
          module: constants.MODULE_RECORDS,
          icon: 'mdi-account-clock',
          title: 'Records',
          to: '/records',
        },
        {
          module: constants.MODULE_WIDGETS,
          title: 'Widgets',
          icon: 'mdi-apps',
          to: '/widgets',
        },
        {
          module: constants.MODULE_SUPPORT,
          title: 'Support',
          icon: 'mdi-face-agent',
          to: '/support',
        },
    ];
export default drawerItems;
